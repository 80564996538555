<template>
<b-card class="rounded shadow" no-body style="width: 500px; margin: 0 auto; max-width: 100%">
    <template #header>
        <div class="h2">
			{{ title }}
		</div>
		<div class="my-2">
			{{ description }}
		</div>
		<div class="small text-muted">
			from {{ fromHost }} 
		</div>
    </template>
	<div class="color-bar d-flex" v-if="isTheme('patriot')">
		<div></div>
		<div></div>
		<div></div>
	</div>
    <template #footer>
        <div class="mb-3">
			Share this article:
		</div>
        <div>
            <ShareNetwork class="p-3" v-for="provider in supported" :url="url" :title="title" :description="description" :key="provider.network" :network="provider.network" v-b-tooltip.hover.bottom="'Share with ' + provider.network">
                <!-- <i class="fa" :class="'fa-' + provider.icon"></i> -->
				<FacebookIcon class="social-icon" v-if="provider.network === 'facebook'" />
				<TwitterIcon class="social-icon" v-if="provider.network === 'twitter'" />
				<LinkedInIcon class="social-icon" v-if="provider.network === 'linkedin'" />
				<SMSIcon class="social-icon" v-if="provider.network === 'sms'" />
				<EmailIcon class="social-icon" v-if="provider.network === 'email'" />
            </ShareNetwork>
        </div>
    </template>
</b-card>
</template>

<script>
import FacebookIcon from '@/assets/images/icons/facebook.svg';
import TwitterIcon from '@/assets/images/icons/twitter.svg';
import LinkedInIcon from '@/assets/images/icons/linkedin.svg';
import SMSIcon from '@/assets/images/icons/sms.svg';
import EmailIcon from '@/assets/images/icons/email.svg';
export default {
    name: 'ShareCard',
    props: ['url', 'title', 'description', 'theme'],
	components: {
		FacebookIcon,
		TwitterIcon,
		LinkedInIcon,
		SMSIcon,
		EmailIcon
	},
	data() {
		return {
			supported: [{
				network: 'facebook',
			}, {
				network: 'twitter',
			}, {
				network: 'linkedin',
			}, {
				network: 'sms',
			}, {
				network: 'email',
			}]
		}
	},
	methods: {
		isTheme(theme) {
			return (this.theme == `theme-${theme}`);
		}
	},
	computed: {
		fromHost () {
			return new URL(decodeURIComponent(this.url)).hostname;
		}
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.card {
	background-color: transparent;
	border: 0 !important
}

.card-header,
.card-body,
.card-footer {
    padding: 1.5rem !important;
}

.card-header {
    border-top-left-radius:  var(--property-border-radius) !important;
	border-top-right-radius:  var(--property-border-radius) !important;
	text-align: left;
	border: 0 !important;
	background-color: var(--color-white);
}

.card-footer {
    border-bottom-left-radius:  var(--property-border-radius) !important;
	border-bottom-right-radius:  var(--property-border-radius) !important;
	border-top: 0 !important;
	background-color: #fafafa;
}

.color-bar {
	div {
		flex-grow: 1;
		height: 5px;
	}
}
</style>
