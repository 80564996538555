<template>
	<div class="d-flex justify-content-center align-items-center theme-container" :class="theme" :style="{backgroundImage}" style="background-position: center;">
		<div class="w-100 text-center">
			<b-container class="w-100">
				<b-row class="mt-3">
					<b-col>
						<ShareCard
							:url="allowedAttributes.url"
							:title="allowedAttributes.title"
							:description="allowedAttributes.description"
							:theme="theme" />
					</b-col>
				</b-row>
				<div class="mt-5">
					<div>
						<router-link to="/">
							<Logo class="logo" />
						</router-link>
					</div>
					<div class="small text-muted mt-3">
						Powered by
						<router-link to="/">sharelink.email</router-link>. All rights reserved.
					</div>
				</div>
			</b-container>
		</div>
	</div>
</template>

<script>
import Logo from '@/assets/images/logo.svg';
import ShareCard from '@/components/ShareCard';
export default {
	name: 'Share',
	components: {
		Logo,
		ShareCard
	},
	data () {
		return {
			allowedThemes: ['default', 'dark', 'patriot'],
			supported: [{
				network: 'facebook',
				icon: 'facebook'
			}, {
				network: 'twitter',
				icon: 'twitter'
			}, {
				network: 'linkedin',
				icon: 'linkedin'
			}, {
				network: 'sms',
				icon: 'comments',
			}, {
				network: 'email',
				icon: 'envelope'
			}]
		}
	},
	computed: {
		backgroundImage () {
			if (this.theme == 'theme-patriot') {
				return "url('/themes/patriot/bg.webp')"
			}
			return "none"
		},
		allowedAttributes () {
			console.log(this.$route.query);
			const { url, title, description, theme='default' } = this.$route.query;
			return { url, title, description, theme };
		},
		theme () {
			if ( this.allowedThemes.includes(this.allowedAttributes.theme) ) {
				return `theme-${this.allowedAttributes.theme}`
			}
			return `theme-default`;
		}
	}
}
</script>

<style lang="scss" scoped>
	.theme-container {
		width: 100%;
		min-height: 100vh !important;
		background-size: cover;
		background-repeat: repeat;
	}

	.footer {
		font-size: var(--property-font-size-xs);
		font-weight: normal;
	}

	.theme-dark {
		// Settings for the page
		background-color: #131517;
		color: white;

		.logo {

			path.letter {
				fill: white;
			}
		}

		// Settings for the card
		/deep/ .card {
			box-shadow: 3px 3px 30px #000;
			border: none;

			.card-header {
				background-color: #1E1F25;
				color: var(--color-white);
			}

			.social-icon {
				path {
					fill: white !important;
				}
			}

			.card-footer {
				background: #272C38;
				.fa {
					color: #fff;
				}
			}
		}
	}

	.theme-patriot {
		// Settings for the page
		background-color: var(--color-light-gray) ;
		background-repeat: repeat;
		// background-image: url("data:image/svg+xml,%3Csvg width='40' height='1' viewBox='0 0 40 1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 0h20v1H0z' fill='%23031070' fill-opacity='0.05' fill-rule='evenodd'/%3E%3C/svg%3E");

		// Settings for the card
		/deep/ .card {

			.card-header {
				background-color: white;
			}

			.color-bar {
				div {
					&:nth-of-type(1) {
						background: red;
					}
					&:nth-of-type(2) {
						background: #eee;
					}
					&:nth-of-type(3) {
						background: blue;
					}
				}
			}

			border-radius: 20px;
			box-shadow: 3px 3px 4rem rgba(0,0,0,0.15);
			border: none;
			.card-footer {
				background-color: #fafafa;
				.fa {
					color: #fff;
				}
			}

			.social-icon {
				&:hover {
					transform: scale(1.2);
				}
			}
		}
	}

</style>

<style lang="scss">


</style>
